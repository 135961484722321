import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardActionArea,
	CardMedia,
	Link,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 150,
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			minHeight: 80,
		}
	},
	actionArea: {
		height: '100%'
	},
	media: {
		width: '100%',
		height: '100%',
		// backgroundSize: 'contain'
	}
}));

function QuickItem({
	className, href, image, color, ...rest
}) {
	const classes = useStyles();

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			{href.includes('http') && (
				<CardActionArea
					className={classes.actionArea}
					component={Link}
					href={href}
				>
					<CardMedia
						className={classes.media}
						image={image}
						src="img"
						style={{ backgroundColor: color }}
					/>
				</CardActionArea>
			)}
			{!href.includes('http') && (
				<CardActionArea
					className={classes.actionArea}
					component={RouterLink}
					to={href}
				>
					<CardMedia
						className={classes.media}
						image={image}
						src="img"
						style={{ backgroundColor: color }}
					/>
				</CardActionArea>
			)}
		</Card>
	);
}

QuickItem.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
};

export default QuickItem;
