import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Grid,
	useMediaQuery,
	useTheme
} from '@material-ui/core';

import QuickItem from './QuickItem';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles(() => ({
	root: {
	}
}));

function QuickMenu({
	className, ...rest
}) {
	const classes = useStyles();
	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

	const { hostname } = window.location;
	const domainName = hostname.substring(hostname.indexOf('.') + 1);

	const [object, setObject] = useState(null);

	useEffect(() => {
		let mounted = true;

		const fetchObject = () => {
			axios.get(WebAPI.PROFILE)
				.then((response) => {
					if (mounted) {
						const { data } = response.data;
						setObject(data);
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Grid
			{...rest}
			className={clsx(classes.root, className)}
			container
			justify="space-between"
			spacing={mobileDevice ? 1 : 3}
		>
			<Grid
				item
				md={8}
				xs={8}
			>
				<Grid
					container
					justify="space-between"
					spacing={mobileDevice ? 1 : 2}
				>
					<Grid
						item
						md={7}
						xs={7}
					>
						<QuickItem
							// href="/content/steps/categories"
							href="https://app.echolink.co/content/articles/categories/4"
							image="/images/backgrounds/quick-access-01.jpg"
							color="#7d46fc"
						/>
					</Grid>
					<Grid
						item
						md={5}
						xs={5}
					>
						<QuickItem
							href="/content/videos/categories"
							image="/images/backgrounds/quick-access-02.jpg"
							color="#4872fd"
						/>
					</Grid>
					<Grid
						item
						md={7}
						xs={7}
					>
						{/* <QuickItem
							href="/events"
							image="/images/backgrounds/quick-access-03.jpg"
							color="#f985cf"
						/> */}
						{object && (
							<QuickItem
								href={`https://${object.sub_domain}.${domainName}/product`}
								image="/images/backgrounds/order.jpg"
								color="#f985cf"
							/>
						)}
					</Grid>
					<Grid
						item
						md={5}
						xs={5}
					>
						<QuickItem
							href="https://drive.google.com"
							image="/images/backgrounds/quick-access-04.jpg"
							color="#f5a771"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				md={4}
				xs={4}
			>
				<QuickItem
					href="/pages"
					image="/images/backgrounds/quick-access-05.jpg"
					color="#ffbf00"
				/>
			</Grid>
			<Grid
				item
				md={12}
				xs={12}
			>
				<QuickItem
					href="/kpi/achievements"
					image="/images/backgrounds/quick-access-06.jpg"
					color="#cda038"
				/>
			</Grid>
			<Grid
				item
				md={12}
				xs={12}
			>
				<QuickItem
					href="/events/meeting"
					image="/images/backgrounds/meeting.jpg"
					color="#1e56d1"
				/>
			</Grid>
		</Grid>
	);
}

QuickMenu.propTypes = {
	className: PropTypes.string,
};

export default QuickMenu;
