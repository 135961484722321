export default {
	app_name: 'Butler',
	btn: {
		yes: 'ใช่',
		no: 'ไม่ใช่',
		ok: 'ตกลง',
		cancel: 'ยกเลิก',
		discard_changes: 'ยกเลิกการเปลี่ยนแปลง',
		save: 'บันทึก',
		close: 'ปิด',
		clear: 'ล้าง',
		applyFilters: 'บันทึกตัวกรอง',
		search: 'ค้นหา',
		delete: 'ลบ',
		signout: 'ออกจากระบบ',
		next: 'ถัดไป',
		previous: 'ก่อนหน้า',
		add: 'เพิ่ม',
		submit: 'Submit',
		setting: 'ตั้งค่า',
		see_all: 'ดูทั้งหมด',
		view_report: 'ดูรายงาน',
		copy: 'คัดลอก',
		approve: 'อนุมัติ',
		start: 'เริ่มต้น',
	},
	dialog: {
		title: 'ข้อความ',
		discard_changes_message: 'ยกเลิการเปลี่ยนแปลงที่เกิดขึ้น?',
		delete_data_message: 'คุณแน่ใจที่จะลบข้อมูลใช่หรือไม่?',
		remove_component_message: 'ต้องการลบข้อมูลนี้ออกใช่หรือไม่?'
	},
	alert: {
		title: {
			recommend: 'คำแนะนำ',
			warning: 'คำแนะนำ'
		}
	},
	badge: {
		status: {
			active: 'เปิดสาธารณะ',
			inactive: 'ซ่อนการมองเห็น',
		},
		member_order: {
			rejected: 'ไม่อนุมัติ',
			waiting: 'รอการตรวจสอบ',
			waiting_admin: 'ส่งต่อผู้ดูแลตรวจสอบ',
			approved: 'อนุมัติแล้ว',
		},
		app: {
			enable: 'เปิดการใช้งาน',
			disable: 'ปิดการใช้งาน',
		},
		kpi: {
			system: 'System'
		}
	},
	preloader: {
		loading: 'ระบบกำลังเตรียมความพร้อม, กรุณารอซักครู่...',
		loading_2: 'เริ่มการแสดงผลข้อมูลที่จำเป็นสำหรับคุณ',
		too_long: 'หากใช้เวลานานเกินไป, กรุณาติดต่อผู้ดูแลระบบ'
	},
	unit: {
		minute: 'นาที',
		time: 'ครั้ง',
		people: 'คน',
		point: 'คะแนน',
	},
	order: {
		status: {
			cancelled: 'ยกเลิกคำสั่งซื้อ',
			new: 'ได้รับคำสั่งซื้อ',
			paid: 'จ่ายเงินแล้ว',
			confirm_paid: 'ยืนยันการจ่ายแล้ว',
			ready_to_ship: 'พร้อมจัดส่ง',
			shipped: 'จัดส่งแล้ว',
			delivered: 'ส่งถึงปลายทางแล้ว',
		},
	},
	nav: {
		data: {
			default: 'การจัดการข้อมูล',
			overview: 'Overview'
		},
		event: 'กิจกรรม',
		news: 'ข่าวและโปรโมชัน',
		page: 'เซลเพจ',
		org: {
			default: 'Indeem',
			onlines: 'Online',
			steps: '8 Steps'
		},
		settings: {
			default: 'ตั้งค่า',
			general: 'ข้อมูลทั่วไป',
			introduction: 'วิดีโอธุรกิจ',
			password: 'เปลี่ยนรหัสผ่าน',
			upgrade: 'อัพเกรดสถานะ',
		},
		account: {
			default: 'การจัดการลูกค้า',
			accounts: 'ลูกค้า',
			orders: 'คำสั่งซื้อ'
		},
		shopping: {
			default: 'ซื้อขายสินค้า',
			stock: {
				default: 'สต็อกสินค้า',
				inventories: 'สินค้า',
				movements: 'ความเคลื่อนไหวสต็อก',
			},
			member_orders: 'รายการสั่งซื้อส่วนตัว',
			agent_orders: 'รายการสั่งซื้อจากตัวแทน',
			customer_orders: {
				default: 'คำสั่งซื้อจากลูกค้า',
				orders: 'รายการคำสั่งซื้อ',
				waiting: 'รายการรอจัดส่ง',
				tracking: 'รายการจัดส่งสินค้า',
			}
		},
		redeem: {
			default: 'แลกของรางวัล',
			gifts: 'รายการของรางวัล',
			histories: 'ประวัติแลกรางวัล',
		},
		member: {
			default: 'การจัดการตัวแทน',
			guests: 'ผู้มุ่งหวัง',
			members: 'สมาชิก',
		},
		kpi: {
			default: 'วัดผล KPI',
			achievements: {
				default: 'เป้าหมาย',
				passport: 'พาสปอร์ต',
				history: 'ประวัติรายวัน',
			},
			leaders: 'รายการผู้พิชิตเป้าหมาย',
		},
		star: {
			default: 'Hall of Fame',
			learners: 'นักเรียนรู้',
			promoters: 'นักโปรโมท',
			qualified: 'ผู้ประสบความสำเร็จ'
		},
		apps: {
			default: 'เชื่อมต่อบริการอื่นๆ',
			liff: 'Line Share',
			page: 'เซลเพจ',
			facebook_group: 'กลุ่มเฟซบุ๊ก'
		},
		shipping: {
			default: 'ระบบขนส่ง',
			general: 'ตั้งค่าทั่วไป',
			couriers: 'รายการขนส่ง'
		},
		payment: {
			default: 'ระบบจ่ายเงินออนไลน์',
			general: 'ตั้งค่าทั่วไป',
			methods: 'วิธีการจ่ายเงิน'
		},
		content: {
			default: 'คอร์สออนไลน์',
			articles: 'บทความ',
			videos: 'วิดีโอ',
		},
		guest_content: {
			default: 'เนื้อหา',
			specials: 'Why ',
			videos: 'คอร์สออนไลน์',
		},
		leads: 'โทรนัด'
	},
	topbar: {
		menu: {
			wallet_member: 'บัตรตัวแทน',
			profile: 'ข้อมูลส่วนตัว',
			logout: 'ออกจากระบบ',
		},
		signout: {
			title: 'แน่ใจหรือไม่?',
			message: 'คุณกำลังดำเนินการออกจากระบบ กรุณาตรวจสอบอีกครั้งเพื่อยืนยัน'
		}
	},
	result_table: {
		all_filter: 'ทั้งหมด',
		filter_action: 'แสดงตัวกรอง',
		hint: ' รายการที่พบ หน้า ',
		hint2: ' จาก ',
		hint_no_page: 'รวมทั้งหมด %{count} รายการ',
		rows_per_page: 'แถวต่อหน้า:',
		page_hint: 'จาก',
		page_hint2: 'มากกว่า ',
		loading: 'กำลังเชื่อมต่อข้อมูล...'
	},
	result_grid: {
		sorting: 'จัดเรียง:',
	},
	empty_data: {
		desc: 'รายละเอียดการค้นหาของคุณไม่ตรงกับข้อมูลที่มีอยู่ กรุณาลองค้นหาด้วย',
		desc_2: 'ข้อมูลอื่นอีกครั้งหรือหากต้องการรีเซ็ตการค้นหาข้อมูลใหม่, เพียงคลิก',
		btn_reset: 'ตั้งค่าค้นหาข้อมูลใหม่',
		remark: 'เพื่อดำเนินการต่อ',
	},
	notifications: {
		title: 'แจ้งเตือน'
	},
	login: {
		title: 'Online Marketing Platform',
		header: 'Online Marketing Platform',
		email: 'ชื่อผู้ใช้งาน / รหัสสมาชิก',
		password: 'รหัสผ่าน',
		login: 'เข้าสู่ระบบ',
		forgot_password: 'ลืมรหัสผ่าน ?',
		or: 'หรือ',
		contact: 'ติดต่อเพื่อสมัครสมาชิก',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
			password: '^กรุณากรอกรหัสผ่าน'
		},
		login_fail: 'อีเมล์ / ชื่อล็อกอิน หรือรหัสผ่านไม่ถูกต้อง, ลองอีกครั้ง!',
		member: {
			header: 'ระยะเวลาใช้งานหมดลงแล้ว',
			contact: 'กรุณาติดต่อผู้ Sponsor',
		}
	},
	forgot_password: {
		title: 'Online Marketing Platform',
		header: 'ระบบจะทำการส่งชื่อผู้ใช้และรหัสผ่านใหม่ ไปยังอีเมลที่ระบุด้านล่างนี้',
		email: 'อีเมล',
		forgot: 'ส่งคำขอรหัสผ่าน',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
		},
		save_success: 'ระบบทำการส่งรายละเอียดเข้าอีเมลเรียบร้อยแล้ว',
		save_fail: 'อีเมล์นี้ไม่มีในระบบ กรุณาติดต่อผู้ดูแลอีกครั้ง',
	},
	privacy: {
		title: 'สัญญาเงื่อนไขและกฏระเบียบ',
		sub_header: 'สัญญาเงื่อนไขและกฏระเบียบ',
		btn_action: 'ดาวน์โหลดเอกสาร PDF'
	},
	wallet: {
		member: {
			title: 'สมัครตัวแทน',
			download_action: 'ดาวน์โหลดบัตรตัวแทน',
			share_action: 'แชร์ลิงค์สมัครตัวแทน',
			share_result: 'Copy ลิงค์สมัครตัวแทนลงใน Clipboard แล้ว',
			download_resulst: 'ส่งบัตรตัวแทนไปที่ Line ส่วนตัวของคุณแล้ว',
			form: {
				header: 'สนใจเข้าร่วมธุรกิจ',
				sub_header: 'กรอกข้อมูลส่วนตัวให้ครบถ้วน เพื่อใช้ในการยืนยันตัวตน',
				btn_action: 'ลงทะเบียน',
				email: 'อีเมล',
				email_placeholder: 'เช่น john.d@gmail.com',
				firstname: 'ชื่อจริง',
				lastname: 'นามสกุล',
				birthdate: 'วันเกิด',
				phone: 'เบอร์โทรศัพท์',
				phone_placeholder: 'เช่น 081 234 5678',
				save_fail: 'อีเมลหรือชื่อล็อกอินของคุณมีสมาชิกคนอื่นใช้แล้ว กรุณาลองใหม่อีกครั้ง',
				validator: {
					email: '^กรุณากรอกอีเมล์',
					email_invalid: '^อีเมล์ไม่ถูกต้อง',
					firstname: '^กรุณากรอกชื่อจริง',
					lastname: '^กรุณากรอกนามสกุล',
					phone: '^กรุณากรอกเบอร์โทรศัพท์',
				},
			},
		},
		member_register: {
			title: 'สมัครตัวแทน',
			header: 'สมัครตัวแทนเพื่อรับสิทธิประโยชน์มากมายของ Platform ภายใต้ทีมของ',
			stepper: {
				step1: {
					default: 'ยืนยันข้อมูลส่วนตัว',
					header: 'ระบุข้อมูลส่วนตัว',
					sub_header: 'กรอกข้อมูลส่วนตัวให้ครบถ้วน เพื่อใช้ในการยืนยันตัวตน',
					btn_action: 'บันทึกข้อมูลส่วนตัว',
					policy1: 'ฉันได้อ่านสัญญาแล้ว ยอมรับและตกลงในเงื่อนไขทุกประการตาม ',
					policy2: 'สัญญาเงื่อนไขและกฏระเบียบนี้',
					profile_image: 'รูปโปรไฟล์และข้อมูลส่วนตัว',
					profile_image_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					profile_image_info: 'ภาพแสดงใบหน้าของผู้สมัครชัดเจน',
					email: 'อีเมล',
					email_placeholder: 'เช่น john.d@gmail.com',
					firstname: 'ชื่อจริง',
					lastname: 'นามสกุล',
					nick_name: 'ชื่อเล่น',
					birthdate: 'วันเกิด',
					card_id_image: 'บัญชีธนาคาร',
					card_id_image_hint: 'แนบบัญชีธนาคารที่นี่',
					card_id_image_info: 'ภาพแสดงรายละเอียดชัดเจน',
					card_id: 'หมายเลขบัตรประชาชน / หนังสือเดินทาง',
					card_id_placeholder: 'รหัส 13 หลักหรือเลขที่หนังสือเดินทาง',
					phone: 'เบอร์โทรศัพท์',
					phone_placeholder: 'เช่น 081 234 5678',
					address: 'ที่อยู่',
					address_placeholder: 'เช่น บ้านเลขที่ 1/127 ถนนเพชรบุรี',
					postal_code: 'รหัสไปรษณีย์',
					province_name: 'จังหวัด',
					city_name: 'อำเภอ/เขต',
					district_name: 'ตำบล/แขวง',
					account_info: 'ช้อมูลการใช้งาน Platform',
					sub_domain: 'ชื่อเว็บไซต์ส่วนตัว',
					sub_domain_hint: 'รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น ขั้นต่ำ 6 ตัวอักษร',
					username: 'ชื่อล็อกอิน',
					username_hint: 'รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น ขั้นต่ำ 8 ตัวอักษร',
					password: 'รหัสผ่าน',
					password_hint: 'รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น ขั้นต่ำ 8 ตัวอักษร',
					confirm_password: 'ยืนยันรหัสผ่าน',
					sale_page_header: 'รายการเซลเพจ',
					sale_page_sub_header: 'ติดตั้งเซลเพจทั้งหมดภายใต้รหัสผู้ใช้งานของคุณ',
					auto_install: 'ยอมรับการติดตั้งอัตโนมัติ',
					save_fail: 'อีเมลหรือชื่อล็อกอินของคุณมีสมาชิกคนอื่นใช้แล้ว กรุณาลองใหม่อีกครั้ง',
					validator: {
						accept_policy: '^กรุณายอมรับเงื่อนไขในสัญญา',
						email: '^กรุณากรอกอีเมล์',
						email_invalid: '^อีเมล์ไม่ถูกต้อง',
						firstname: '^กรุณากรอกชื่อจริง',
						lastname: '^กรุณากรอกนามสกุล',
						nick_name: '^กรุณากรอกชื่อเล่น',
						birthdate: '^กรุณากรอกวันเกิด',
						card_id: '^กรุณากรอกหมายเลขบัตรประชาชน',
						phone: '^กรุณากรอกเบอร์โทรศัพท์',
						address: '^กรุณากรอกที่อยู่',
						postal_code: '^กรุณากรอกรหัสไปรษณีย์',
						sub_domain: '^กรุณากรอกชื่อเว็บไซต์ส่วนตัว',
						sub_domain_length: '^ความยาวชื่อเว็บไซต์ส่วนตัวอย่างน้อย 6 ตัวอักษร',
						sub_domain_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
						username: '^กรุณากรอกชื่อล็อกอิน',
						username_length: '^ความยาวชื่อล็อกอินอย่างน้อย 6 ตัวอักษร',
						username_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
						password: '^กรุณากรอกรหัสผ่าน',
						password_length: '^ความยาวรหัสผ่านอย่างน้อย 8 ตัวอักษร',
						password_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
						password_not_match: '^รหัสผ่านไม่ตรงกัน'
					},
				},
				step2: {
					default: 'ประเภทบัญชีใช้งาน',
					header: 'เลือก Package ที่เหมาะสมกับคุณ',
					sub_header: 'Package สำหรับผู้ใช้ใหม่ตามรายละเอียดด้านล่าง โดยคุณจะได้รับสิทธิประโยชน์มากมายกับ Platform มากขึ้น ตาม Package ที่เลือก',
					btn_action: 'ยืนยัน Package',
					btn_back: 'แก้ไขข้อมูลส่วนตัว',
				},
				step3: {
					default: 'ชำระเงินการสมัคร',
					header: 'แจ้งชำระเงินกับแม่ทีม',
					sub_header: 'ชำระเงินด้วยวิธีการโอนเงินผ่านทางบัญชีธนาคาร และแจ้งรายละเอียดให้ผู้ดูแลระบบตรวจสอบ',
					account_name: 'ชื่อบัญชี',
					bank_code: 'ธนาคาร',
					transaction_date: 'วันที่โอนเงิน',
					transaction_time: 'เวลาที่โอนงิน',
					attach: 'แนบสลิปการโอนเงิน *',
					attach_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					attach_info: 'ภาพแสดงรายละเอียดการโอนเงินชัดเจน',
					btn_action: 'แจ้งรายละเอียดการชำระเงิน',
					btn_back: 'เลือก Package ใหม่',
					validator: {
						account_name: '^กรุณากรอกชื่อบัญชี',
						bank_code: '^กรุณาเลือกธนาตาร',
					},
				},
			},
			all_done: 'แจ้งชำระเงินเรียบร้อย รอผู้ดูแลระบบตรวจสอบข้อมูล...'
		}
	},
	overview: {
		welcome: {
			title: 'สวัสดี !!',
			caption: 'ยินดีต้อนรับท่านเข้าสู่สังคม %{title}',
			btn_send: 'ส่งบัญชีธนาคาร',
			page: {
				restore_success: 'ติดตั้งเซลเพจเรียบร้อยแล้ว',
				dialog: {
					restore_message: 'คุณมีเซลเพจที่ยังไม่ได้ติดตั้งจำนวน %{count} เซลเพจ ต้องการให้ระบบติดตั้งอัตโนมัติหรือไม่ ?',
				}
			}
		},
		restore_page: {
			title: 'อัพเดตข้อมูลล่าสุดให้เซลเพจของคุณ',
			btn_send: 'ตรวจสอบอัพเดต',
			restore_fail: 'กรุณากรอกข้อมูลเบอร์โทรศัพท์ และ Social Network ให้ครบถ้วนก่อน',
			restore_success: 'เซลเพจของคุณอัพเดตล่าสุดแล้ว',
		},
		quick_access: {
			label: 'กิจกรรม',
			caption: 'ประจำเดือนนี้',
			label_2: 'เซลเพจ',
			caption_2: 'ตัวช่วยสร้าง',
			label_3: 'วัดผล KPI',
			caption_3: 'พิชิตเป้าหมาย',
			label_4: 'วิดีโอ',
			caption_4: 'ศูนย์ความรู้',
			only_member: 'เฉพาะสมาชิก'
		},
		guest_quick_access: {
			label: 'กิจกรรม',
			caption: 'ประจำเดือนนี้',
			label_2: 'เนื้อหา',
			caption_2: 'คอร์สออนไลน์',
		},
		stats: {
			registered_member: 'ลงทะเบียนเว็บโปรโมท',
			upgraded_member: 'สนใจอัพเกรด',
			team_member: 'ทีมงานของคุณ',
			order: 'คำสั่งซื้อ'
		},
		lead: {
			chart_title: 'สถิติผู้ลงทะเบียนผ่านเซลเพจของคุณในเดือนนี้',
			chart_unit: 'จำนวน %{label} คน',
			all_title: 'จำนวนผู้มุ่งหวังทั้งหมด',
			all_unit: 'ตั้งแต่คุณเริ่มต้นใช้งาน',
			approved_title: 'จำนวนผู้มุ่งหวังที่สนใจ',
			approved_unit: 'ที่คุณอนุมัติให้เข้าเรียนรู้ในระบบได้',
		},
		member: {
			leader: 'Leadership / ผู้สำเร็จประจำเดือน',
			team: 'นักธุรกิจเข้าระบบล่าสุด',
		},
		countdown: {
			title: 'ระยะเวลาการใช้งานคงเหลือ',
			caption: 'เมื่อหมดเวลา จะไม่สามารถดูวิดีโอนี้ได้'
		},
		signup: {
			title: 'สมัครสมาชิก',
			caption: 'เพียงแค่คุณเป็นสมากชิกครั้งเดียวก็ได้รับสิทธิ์ เข้าโปรแกรม AAP [Apco Affiliate Program] ตลอด 100 ปี สอบถามคลิกที่ปุ่มด้านล่าง',
			btn_action: 'สมัครสมาชิกทันที คลิ๊ก!!',
		},
		campaign: {
			title: 'เริ่มต้นวันนี้ด้วย Voucher ของคุณ',
			caption: 'เริ่มใช้ Voucher เพื่อเข้าเรียนรู้คอร์สออนไลน์ที่ได้จัดเตรียมไว้พิเศษสำหรับคุณ',
			btn_action: 'แลก Vouncher ตอนนี้',
			btn_disable: 'รอการอนุมัติ Vouncher',
			confirm_message: 'ต้องการนำ Vouncher เพื่อแลกรับคอร์สออนไลน์หรือไม่?',
		},
		member_card: {
			contact: 'ติดต่อผู้ดูแลโครงการ',
			joined_date: 'เข้าร่วมวันที่',
		},
		total_income: {
			title: 'ยอดขายทั้งหมด',
			subheader: 'รวมทั้งหมดตั้งแต่เริ่มใช้งานระบบ',
			report_caption: 'ดูรายการสั่งซื้อทั้งหมดจากไหน?'
		},
		monthly_income: {
			title: 'ยอดขายในเดือนนี้',
			subheader: 'เป้าหมายยอดขายรวมทั้งหมด 100k',
			target_complete: 'คุณถึงเป้าหมายของเดือนนี้แล้ว!',
			report_caption: 'ดูรายการยอดขายเดือนนี้จากไหน?'
		}
	},
	events: {
		title: 'รายการอีเวนท์',
		header: 'ปฏิทินอีเวนท์',
		action: 'เพิ่มรายการ',
		toolbar: {
			prev: 'ก่อนหน้า',
			today: 'วันนี้',
			next: 'ต่อไป',
			views: {
				month: 'เดือน',
				week: 'สัปดาห์',
				day: 'วัน',
				agenda: 'กำหนดการ'
			}
		},
		detail: {
			title: 'หัวข้อ',
			description: 'รายละเอียด',
			all_day: 'ตลอดวัน',
			btn_meeting: 'เข้าร่วม Meeting',
		},
	},
	news: {
		title: 'รายการข่าวและโปรโมชัน',
		header: 'รายการข่าวและโปรโมชัน',
		search_placeholder: 'หัวข้อข่าวและโปรโมชัน',
		no_data: 'ไม่พบข่าวและโปรโมชัน',
		sorting: {
			item: 'ชื่อ ก-ฮ',
			item2: 'ล่าสุด',
		},
		date: 'นำเสนอวันที่:'
	},
	settings: {
		title: 'ตั้งค่า',
		tab: {
			default: 'ข้อมูลทั่วไป',
			profile: 'ข้อมูลส่วนตัว',
			group_id: 'Member group',
			group_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
			sub_domain: 'ชื่อเว็บไซต์ส่วนตัว',
			sub_domain_hint: 'รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น ขั้นต่ำ 3 ตัวอักษร',
			username: 'ชื่อล็อกอิน',
			username_hint: 'รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น ขั้นต่ำ 3 ตัวอักษร',
			email: 'อีเมล',
			signup_url: 'ลิงค์สมัครนักธุรกิจ',
			signup_url_hint: 'ติดต่อผู้ดูแลระบบเพื่อรับลิงค์ส่วนตัวของคุณ',
			firstname: 'ชื่อจริง',
			lastname: 'นามสกุล',
			nick_name: 'ชื่อเล่น',
			birthdate: 'วันเกิด',
			card_id: 'หมายเลขบัตรประชาชน',
			phone: 'เบอร์โทรศัพท์',
			address: 'ที่อยู่',
			postal_code: 'รหัสไปรษณีย์',
			province_name: 'จังหวัด',
			city_name: 'อำเภอ/เขต',
			district_name: 'ตำบล/แขวง',
			phone_placeholder: '081 234 5678',
			social: 'Social Network',
			line: 'Line Id',
			line_placeholder: '@line',
			facebook: 'Facebook',
			facebook_placeholder: '@facebook',
			instagram: 'Instagram',
			instagram_placeholder: '@instagram',
			hashtag: 'แฮชแท็ก',
			hashtag_placeholder: 'ceoonline',
			save_fail: 'ชื่อเว็บไซต์ส่วนตัว อีเมล หรือ ชื่อล็อกอินของคุณมีสมาชิกคนอื่นใช้แล้ว กรุณาลองใหม่อีกครั้ง',
			save_success: 'บันทึกเรียบร้อยแล้วว',
			upload_profile_success: 'เปลี่ยนภาพโปรไฟล์เรียบร้อยแล้ว',
			validator: {
				sub_domain: '^กรุณากรอกชื่อเว็บไซต์ส่วนตัว',
				sub_domain_length: '^ความยาวชื่อเว็บไซต์ส่วนตัวอย่างน้อย 6 ตัวอักษร',
				sub_domain_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
				email_invalid: '^อีเมล์ไม่ถูกต้อง',
				username: '^กรุณากรอกชื่อล็อกอิน',
				username_length: '^ความยาวชื่อล็อกอินอย่างน้อย 6 ตัวอักษร',
				username_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
				signup_url_invalid: '^กรุณาระบุ http:// หรือ https:// นำหน้า',
				firstname: '^กรุณากรอกชื่อจริง',
				lastname: '^กรุณากรอกนามสกุล',
				nick_name: '^กรุณากรอกชื่อเล่น',
				card_id: '^กรุณากรอกหมายเลขบัตรประชาชน',
				phone: '^กรุณากรอกเบอร์โทรศัพท์',
				address: '^กรุณากรอกที่อยู่',
				postal_code: '^กรุณากรอกรหัสไปรษณีย์'
			},
		},
		tab2: {
			default: 'ความปลอดภัย',
			change_password: 'เปลี่ยนรหัสผ่าน',
			password: 'รหัสผ่าน',
			confirm_password: 'ยืนยันรหัสผ่าน',
			save_success: 'บันทึกเรียบร้อยแล้วว',
			validator: {
				password: '^กรุณากรอกรหัสผ่าน',
				confirm_password: '^กรุณากรอกยืนยันรหัสผ่าน',
				password_not_match: 'รหัสผ่านไม่ตรงกัน'
			},
		},
		tab3: {
			default: 'Line Notify',
			stepper: {
				step1: {
					default: 'ยืนยันข้อมูล Line ส่วนตัว',
					header: 'รับการแจ้งเตือนจากเว็บเซอร์วิสทาง LINE',
					sub_header: 'หลังเสร็จสิ้นการเชื่อมต่อกับเว็บเซอร์วิสแล้ว คุณจะได้รับการแจ้งเตือนจากบัญชีทางการ "LINE Notify" ซึ่งให้บริการโดย LINE คุณสามารถเชื่อมต่อกับบริการที่หลากหลาย และรับการแจ้งเตือนทางกลุ่มได้ด้วย',
					btn_action: 'ยืนยันข้อมูล Line Account'
				},
				step2: {
					default: 'สร้าง Line Access Token',
					header: 'สร้าง Line Notify Access Token',
					sub_header: 'เมื่อยืนยันตัวตนผ่าน Line แล้ว ขั้นตอนต่อไปคือการขอ Access Token สำหรับส่งข้อความผ่าน "LINE Notify"',
					btn_action: 'สร้าง Token'
				},
				step3: {
					default: 'บันทึก Token ลงข้อมูลส่วนตัว',
					header: 'บันทึกข้อมูล Access Token',
					sub_header: 'โปรดอย่าส่ง Access Token (แสดงด้านล่าง) ให้บุคคลอื่นๆ เนื่องจากข้อมูลนี้เป็นข้อมูลเฉพาะของคุณเองเท่านั้น',
					btn_action: 'Update Profile with Token',
					testing_message: 'เริ่มต้นการเชื่อมต่อ Line Notify'
				}
			},
			save_success: 'บันทึก Line Notify Token เรียบร้อยแล้วว',
			fail_success: 'ไม่สามารถร้องขอ Line Notify Token ได้, กรุณาเริ่มขั้นตอนที่ 1 ใหม่',
		},
		tab4: {
			default: 'วิดีโอธุรกิจ',
		},
		tab5: {
			default: 'บัญชีธนาคาร',
			introduction: 'วิดีโอแนะนำธุรกิจ',
			introduction_url: 'Url วิดีโอ',
			introduction_url_hint: 'รองรับ YouTube, Vimeo',
			save_success: 'บันทึกเรียบร้อยแล้วว',
			validator: {
				introduction: '^กรุณากรอกวิดีโอ',
			},
		},
		upgrade: {
			title: 'อัพเกรดสถานะ',
			header: 'อัพเกรดสถานะเพื่อรับสิทธิประโยชน์มากมายของ Platform',
			stepper: {
				step1: {
					default: 'ยืนยันข้อมูลส่วนตัว',
					header: 'ระบุข้อมูลส่วนตัว',
					sub_header: 'กรอกข้อมูลส่วนตัวให้ครบถ้วน เพื่อใช้ในการยืนยันตัวตนกับผู้ดูแลระบบ',
					btn_action: 'บันทึกข้อมูลส่วนตัว'
				},
				step2: {
					default: 'อัพเกรดบัญชีผู้ใช้งาน',
					header: 'เลือก Package ที่เหมาะสมกับคุณ',
					sub_header: 'อัพเกรดบัญชีผู้ใช้งานตาม Package ที่คุณเลือก โดยคุณจะได้รับสิทธิประโยชน์มากมายกับ Platform มากขึ้น ตาม Package ที่เลือก',
					btn_action: 'ยืนยัน Package'
				},
				step3: {
					default: 'ชำระเงินการอัพเกรด',
					header: 'แจ้งชำระเงินกับผู้ดูแลระบบ',
					sub_header: 'ชำระเงินด้วยวิธีการโอนเงินผ่านทางบัญชีธนาคาร และแจ้งรายละเอียดให้ผู้ดูแลระบบตรวจสอบ',
					account_name: 'ชื่อบัญชี',
					bank_code: 'ธนาคาร',
					transaction_date: 'วันที่โอนเงิน',
					transaction_time: 'เวลาที่โอนงิน',
					attach: 'แนบสลิปการโอนเงิน',
					attach_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					attach_info: 'ภาพแสดงรายละเอียดการโอนเงินชัดเจน',
					btn_action: 'แจ้งรายละเอียดการชำระเงิน',
					validator: {
						account_name: '^กรุณากรอกชื่อบัญชี',
						bank_code: '^กรุณาเลือกธนาตาร',
					},
				},
			},
			all_done: 'แจ้งชำระเงินเรียบร้อย รอผู้ดูแลระบบตรวจสอบข้อมูล...'
		}
	},
	page: {
		title: 'เซลเพจ',
		header: 'รายการเซลเพจ',
		action: 'เพิ่มรายการ',
		import_action: 'ดาวน์โหลดเซลเพจตั้งต้น',
		limit: 'จำนวนเซลเพจที่สร้างแล้ว %{total}/%{limit} หน้า',
		no_data: {
			title: 'ไม่พบรายการเซลเพจ',
			desc: 'คุณสร้างสามารถสร้างเซลเพจของคุณเองได้',
			desc_2: 'ผ่านตัวช่วยสร้างเซลเพจที่ระบบจัดเตรียมไว้ให้สำหรับคุณ, เพียงคลิก',
			btn_add: 'เพิ่มเซลเพจใหม่ทันที',
			remark: 'เพื่อดำเนินการต่อ',
		},
		card: {
			default: 'หน้าหลัก',
		},
		sorting: {
			item: 'ชื่อ ก-ฮ',
			item2: 'ล่าสุด',
			item3: 'เรตติ้ง',
		},
		create: {
			header: 'สร้างข้อมูลเซลเพจ',
			save_fail: 'ไม่สามารถบันทึกข้อมูลเซลเพจ เนื่องจากลิงค์โปรโมทมีอยู่แล้วในระบบ',
		},
		main: {
			title: 'ดาวน์โหลดเซลเพจตั้งต้น',
			subheader: 'ด้านล่างนี้เป็นเซลเพจกลางที่ผู้ใช้สามารถนำไปใช้โปรโมทได้',
			item_action: 'ดาวน์โหลด',
			item_status: 'ดาวน์โหลดแล้ว',
		},
		detail: {
			title: 'แก้ไขรายการ​เซลเพจ',
			header: 'จัดการข้อมูลเซลเพจ',
			shopping_header: 'โปรโมทเว็บไซต์สินค้า',
			tab: {
				title: 'ข้อมูลทั่วไป',
				general: {
					header: 'ข้อมูลทั่วไป',
					type: 'ประเภท',
					type_lead: 'ค้นหาผู้มุ่งหวัง',
					type_product: 'ขายสินค้า',
					type_business: 'แนะนำธุรกิจ',
					type_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					product_id: 'เลือกสินค้า',
					product_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title: 'ชื่อเซลเพจ',
					title_hint: 'จำกัดตัวอักษร %{count}/50',
					description: 'คำอธิบายเซลเพจ',
					real_url: 'ลิงค์จริง',
					shortened_url: 'ลิงค์โปรโมท',
					shortened_url_placeholder: 'ไม่พบลิงค์โปรโมท คลิกปุ่มด้านขวาเพื่อสร้างลิงค์ใหม่',
					thanks_redirect_url: 'ลิงค์ Redirect สำหรับหน้าขอบคุณ',
					thanks_redirect_url_hint: 'เข้าสู่ลิงค์นี้โดยอัตโนมัติเมื่อเข้าสู่หน้าขอบคุณ',
					thanks_redirect_delay: 'ระยะเวลาก่อนเปลี่ยนลิงค์​ (วินาที)',
					is_default: 'ตั้งค่าเป็นหน้าหลักของคุณ',
					is_default_hint: 'เปิด-ปิด เพื่อตั้งค่าเซลเพจเป็นหน้าหลักโดเมนของคุณ',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงเซลเพจต่อผู้ใช้ทั่วไป',
					copy_action: 'คัดลอกลิงค์',
					copy_result: 'คัดลอกลิงค์เรียบร้อยแล้ว',
					product_rates: {
						header: 'กำหนดรูปแบบสินค้ารายการย่อย',
						title: 'รายละเอียดสินค้า',
						price: 'ราคาเต็ม',
						sale_price: 'ราคาขาย',
						no_data: 'ไม่พบรูปแบบสินค้ารายการย่อยที่กำหนดไว้'
					},
					rates: {
						header: 'กำหนดค่าขนส่งตามยอดสั่งซื้อ',
						subheader: '***หากไม่คิดค่าจัดส่ง สามารถข้ามการกรอกส่วนนี้ได้',
						startPrice: 'ยอดสั่งซื้อระหว่าง',
						endPrice: 'ถึง',
						shippingPrice: 'คิดค่าขนส่ง',
						no_data: 'ไม่พบรายการค่าขนส่งกำหนดไว้'
					},
					ob_products: {
						header: 'กำหนดสินค้า Order Bump',
						product_id: 'เลือกสินค้า',
						product_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
						price: 'ราคาเต็ม',
						sale_price: 'ราคาขาย',
						no_data: 'ไม่พบรายการสินค้าที่กำหนดไว้'
					},
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 480x480'
					},
					logo: {
						header: 'อัพโหลดภาพโลโก้',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดความสูงภาพแนะนำ 64px'
					},
					validator: {
						title: '^กรุณากรอกชื่อเซลเพจ',
						title_length: '^ความยาวชื่อเซลเพจอย่างน้อย 6 ตัวอักษร',
						title_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
						product_id: '^กรุณาเลือกสินค้า',
					},
					other_actions: {
						header: 'ดำเนินการอื่นๆ',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลเซลเพจ'
					}
				},
				caption: {
					header: 'Caption สำหรับแชร์ผ่าน Line',
					caption: 'ข้อความสำหรับแชร์',
				},
				social: {
					header: 'Social Network',
					line: 'Line Id',
					line_placeholder: '@line',
					messenger: 'Facebook messenger',
					messenger_placeholder: '@m.me',
					phone: 'เบอร์โทรศัพท์',
					phone_placeholder: '081 234 5678',
				},
				save_success: 'บันทึกข้อมูลเซลเพจเรียบร้อยแล้ว',
				save_fail: 'ไม่สามารถบันทึกข้อมูลเซลเพจ เนื่องจากลิงค์โปรโมทมีอยู่แล้วในระบบ',
			},
			tab2: {
				title: 'Page Editor',
				title_2: 'Thanks Editor',
				no_data: 'ไม่พบการแสดงผลในหน้านี้ กรุณาเลือกเครื่องมือทางด้านขวา',
				components: {
					text: 'ข้อความ',
					image: 'รูปภาพ',
					video: 'วิดีโอ',
					contact_form: 'ฟอร์มติดต่อ',
					call_to_actions: 'Call to Actions',
					slide_images: 'สไลด์รูปภาพ',
					slide_products: 'ขายสินค้า',
					countdown_timer: 'Flash Sale',
				},
				blocks: {
					slide_images: {
						image_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						image_info: 'ภาพทั้งหมดที่นำมาใส่ควรมีขนาดเท่ากัน'
					},
					slide_products: {
						price: 'ราคาเต็ม',
						sale_price: 'ราคาขาย',
						no_data: 'ไม่พบรายการสินค้า กด + เพื่อเพิ่มสินค้า',
						modal: {
							header: 'เพิ่มรายการสินค้า',
							search_placeholder: 'ค้นหาชื่อสินค้า',
						}
					},
					image: {
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 800x800',
						action: 'ตั้งค่ารูปภาพ',
						settings: {
							header: 'ตั้งค่ารูปภาพ',
							sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ ได้ตรงตามความต้องการ',
							link: 'ลิงค์',
						}
					},
					contact_form: {
						email: 'อีเมล',
						email_hint: 'john.doe@mail.com',
						firstname: 'ชื่อจริง',
						firstname_hint: 'John',
						lastname: 'นามสกุล',
						lastname_hint: 'Doe',
						phone: 'เบอร์โทรศัพท์',
						phone_hint: '089 281 8212',
						action: 'ตั้งค่าฟอร์ม',
						settings: {
							header: 'ตั้งค่าฟอร์มติดต่อ',
							sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ ได้ตรงตามความต้องการ',
							mode: 'รูปแบบการแสดงผล',
							mode_normal: 'ปกติ',
							mode_popup: 'ป๊อบอัพ',
						}
					},
					video: {
						label: 'Url วิดีโอ',
						hint: 'รองรับ YouTube, Vimeo'
					},
					two_block_image: {
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 800x800'
					},
					countdown_timer: {
						title: 'รายละเอียด Flash Sale',
						target_date: 'วันที่สิ้นสุด',
						target_time: 'เวลา'
					}
				},
				actions: {
					up: 'เลื่อนขึ้น',
					down: 'เลื่อนลง',
					remove: 'นำออก'
				},
				butttons: {
					header: 'ตั้งค่าปุ่ม Call to Actions',
					sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ เพื่อให้ปุ่มแสดงผลต่อผู้ใช้ได้ตรงตามความต้องการ',
					text: 'ข้อความ',
					link: 'ลิงค์',
					color: 'สี',
					outlined: 'เส้นขอบ',
					outlined_hint: 'แสดงเส้นขอบพื้นหลักตามสีที่เลือก และไม่แสดงผลพื้นหลังของปุ่ม'
				},
				settings: {
					header: 'ตั้งค่า',
					sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ เพื่อให้การจัดการ Page ทำได้ง่ายและตามความต้องการของผู้ใช้มากขึ้น',
					percent_line: 'แสดงระดับการ Scroll',
					percent_line_hint: 'สำหรับ Page Scroll 0 - 100% ซึ่งจะสอดคล้องกับ Facebook Pixel',
					display_mobile: 'ตัวเลือกแสดงผลบน PC, Laptop หรือมือถือ',
					display_mobile_hint: '"เปิด" เพื่อตั้งค่า Page เป็นแบบกว้างสำหรับ PC, Laptop และ "ปิด" เพื่อตั้งค่าแบบเล็กสำหรับบนมือถือ',
				},
				save_success: 'บันทึกข้อมูลเซลเพจเรียบร้อยแล้ว',
			},
			tab3: {
				title: 'Analytics',
				seo_title: 'Google SEO',
				seo_subheader: 'เพิ่มจำนวและคุณภาพ Traffic การเข้าถึงเว็บไซต์ของคุณผ่าน Organic search engine',
				meta_title: 'Title',
				meta_title_hint: 'จำกัดตัวอักษร %{count}/60',
				meta_desc: 'Description',
				meta_desc_hint: 'จำกัดตัวอักษร %{count}/160',
				meta_keyword: 'Keyword',
				meta_keyword_hint: 'จำกัดตัวอักษร %{count}/255',
				meta_keyword_placeholder: 'กรอกคีย์เวิร์ดและกดปุ่ม "Enter"',
				pixel_title: 'Facebook Pixel',
				pixel_subheader: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เป็น Analytics Tools ที่ช่วยให้ Facebook บันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์ได้',
				og_title: 'Title',
				og_title_hint: 'จำกัดตัวอักษร %{count}/95',
				og_desc: 'Description',
				og_desc_hint: 'จำกัดตัวอักษร %{count}/155',
				og_image: 'ภาพประกอบ',
				og_image_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				og_image_info: 'ขนาดภาพแนะนำ 1200x630',
				validator: {
					meta_title: '^กรุณากรอก Title',
					meta_desc: '^กรุณากรอก Description',
					og_title: '^กรุณากรอก Title',
					og_desc: '^กรุณากรอก Description',
				},
				save_success: 'บันทึกข้อมูล Analytics เรียบร้อยแล้ว',
			},
			tab4: {
				title: 'Ads & Pixel',
				google: {
					title: 'Google Tag Manager',
					sub_header: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เพื่อบันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์',
					gtm_container_id: 'รหัสคอนเทนเนอร์',
					validator: {
						gtm_container_id: '^กรุณากรอกรหัสคอนเทนเนอร์',
					}
				},
				facebook: {
					title: 'Facebook Pixel',
					sub_header: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เพื่อบันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์',
					facebook_domain_verify: 'รหัสยืนยันโดเมน',
					facebook_domain_verify_hint: 'อัพเกรดความน่าเชื่อถือของโดเมนเพื่อรองรับ iOS 14',
					main_facebook_pixel: 'Pixel หลัก',
					others_facebook_pixel: 'Pixel รอง',
					others_facebook_pixel_hint: 'ไม่จำกัดจำนวน Pixel รอง',
					others_facebook_pixel_placeholder: 'กรอก Pixel และกดปุ่ม "Enter"',
					main_facebook_token: 'Conversion Token หลัก',
					others_facebook_token: 'Conversion Token รอง',
					others_facebook_token_hint: 'ไม่จำกัดจำนวน Conversion Token รอง',
					others_facebook_token_placeholder: 'กรอก Conversion Token และกดปุ่ม "Enter"',
					validator: {
						facebook_domain_verify: '^กรุณากรอกรหัสยืนยันโดเมน',
						main_facebook_pixel: '^กรุณากรอก Pixel หลัก',
						main_facebook_token: '^กรุณากรอก Conversion Token หลัก',
					}
				},
				tiktok: {
					title: 'Tiktok - Website Pixel',
					sub_header: 'เพิ่มประสิทธิภาพในการเข้าถึงเว็บไซต์ของคุณผ่าน Tiktok',
					main_tiktok_pixel: 'Pixel หลัก',
					others_tiktok_pixel: 'Pixel รอง',
					others_tiktok_pixel_hint: 'ไม่จำกัดจำนวน Pixel รอง',
					others_tiktok_pixel_placeholder: 'กรอก Pixel และกดปุ่ม "Enter"',
					validator: {
						main_tiktok_pixel: '^กรุณากรอก Pixel หลัก',
					}
				},
				line: {
					title: 'Line Tag',
					sub_header: 'ติดตั้ง Base Code เพื่อเริ่มใช้งานแทร็กกิ้ง',
					line_base_code: 'Base Code',
				},
				save_success: 'บันทึกข้อมูล Ads & Pixel เรียบร้อยแล้ว',
			},
		}
	},
	shopping: {
		stock: {
			inventories: {
				title: 'รายการสินค้า',
				header: 'รายการสินค้า',
				search_placeholder: 'ชื่อสินค้า',
				result_title: 'รายการสินค้าทั้งหมด',
				item_col: 'ชื่อสินค้า',
				item_col2: 'หมวดหมู่',
				item_col3: 'จำนวน',
				item_col4: 'อัพเดตล่าสุด',
				no_data: 'ไม่พบรายการสินค้าในสต็อก'
			},
			movements: {
				title: 'รายการความเคลื่อนไหวสินค้า',
				header: 'รายการความเคลื่อนไหวสินค้า',
				search: {
					search_placeholder: 'ค้นหาชื่อสินค้า',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				result_title: 'รายการสินค้าทั้งหมด',
				item_col: 'ชื่อสินค้า',
				item_col2: 'หมวดหมู่',
				item_col3: 'ต้นทาง/ปลายทาง',
				item_col3_default: 'บริษัท',
				item_col4: 'จำนวน',
				item_col5: 'เกิดขึ้นเมื่อ',
				no_data: 'ไม่พบรายการความเคลื่อนไหวสินค้า'
			},
		},
		member_orders: {
			title: 'รายการคำสั่งซื้อส่วนตัว',
			header: 'รายการคำสั่งซื้อส่วนตัว',
			action: 'เพิ่มรายการ',
			search: {
				search_placeholder: 'ค้นหาจากหมายเลขคำสั่งซื้อ',
				status: 'สถานะ',
				all_status: 'ทั้งหมด',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			result_title: 'รายการคำสั่งซื้อทั้งหมด',
			item_col: 'หมายเลขคำสั่งซื้อ',
			item_col2: 'ยอดรวมทั้งหมด',
			item_col3: 'สถานะ',
			item_col4: 'วันที่สั่งซื้อ',
			item_col5: 'Action',
			no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อส่วนตัว',
			create: {
				title: 'รายการคำสั่งซื้อส่วนตัว',
				header: 'สร้างรายการคำสั่งซื้อส่วนตัว',
				no_data: 'กรุณาเพิ่มสินค้าเพื่อทำรายการสั่งซื้อ...',
				btn_create: 'เพิ่มสินค้า',
				modal: {
					header: 'เพิ่มรายการสินค้า',
					search_placeholder: 'ค้นหาชื่อสินค้า',
				}
			},
			detail: {
				title: 'คำสั่งซื้อส่วนตัว',
				header: 'จัดการข้อมูลคำสั่งซ์้อส่วนตัว',
				create_form_header: 'เพิ่มรายการสินค้า',
				create_form_sub_header: 'หมายเลขคำสั่งซื้อจะถูกสร้างให้อัตโนมัติหลังจากบันทึกข้อมูล',
				form_header: 'หมายเลขคำสั่งซื้อ',
				form_sub_header: 'รายการสินค้า',
				created_date: 'จัดทำวันที่',
				courier_name: 'รูปแบบขนส่ง',
				total_price: 'ยอดรวมทั้งหมด',
				total_point: 'คะแนนสะสม',
				results: {
					item_col0: 'ลำดับ',
					item_col: 'ชื่อสินค้า',
					item_col2: 'ราคาต่อชิ้น',
					item_col3: 'จำนวน',
					item_col4: 'ยอดรวม',
					unit_price: 'ราคา',
					point: 'คะแนนต่อชิ้น',
					qty: 'จำนวน',
					total: 'ยอดรวม'
				},
				shipping: {
					header: 'การจัดส่งสินค้า',
					courier_id: 'เลือกขนส่ง',
					courier_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					default_courier: 'นัดรับสินค้า',
					shipping_price: 'ค่าขนส่ง'
				},
				summary: {
					header: 'สรุปคำสั่งซื้อ',
					items: '%{items} รายการ',
					units: 'จำนวน %{units} ชิ้น',
					total_price: 'รวมทั้งหมด',
					shipping_price: 'ค่าขนส่ง',
					discount_price: 'ส่วนลด',
					net_price: 'ยอดรวมทั้งหมด',
				},
				image: {
					header: 'ภาพหลักฐานชำระเงิน',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ภาพสลิปการโอนเงินผ่านธนาคาร'
				},
				remark: {
					header: 'อื่นๆ',
					remark: 'รายละเอียดเพิ่มเติม'
				},
				save_success: 'บันทึกข้อมูลรายการคำสั่งซื้อส่วนตัวเรียบร้อยแล้ว',
			}
		},
		agent_orders: {
			title: 'รายการคำสั่งซื้อของตัวแทน',
			header: 'รายการคำสั่งซื้อของตัวแทน',
			search: {
				search_placeholder: 'หมายเลขคำสั่งซื้อ, ชื่อตัวแทน',
				status: 'สถานะ',
				all_status: 'ทั้งหมด',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			result_title: 'รายการคำสั่งซื้อทั้งหมด',
			item_col: 'หมายเลขคำสั่งซื้อ',
			item_col2: 'ยอดรวมทั้งหมด',
			item_col3: 'สถานะ',
			item_col4: 'วันที่สั่งซื้อ',
			item_col5: 'Action',
			no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อของตัวแทน',
			detail: {
				title: 'คำสั่งซื้อส่วนตัว',
				header: 'จัดการข้อมูลคำสั่งซ์้อส่วนตัว',
				reject_aciton: 'ไม่อนุมัติ',
				approve_aciton: 'บันทึกอนุมัติ',
				form_header: 'หมายเลขคำสั่งซื้อ',
				form_sub_header: 'รายละเอียดสินค้าและหลักฐานการชำระเงิน',
				total_price: 'ยอดรวมทั้งสิ้น',
				total_point: 'คะแนนสะสม',
				item: {
					out_of_stock: 'สินค้าไม่พอ ',
				},
				save_success: 'บันทึกข้อมูลรายการคำสั่งซื้อของตัวแทนเรียบร้อยแล้ว',
				image: {
					header: 'ภาพหลักฐานชำระเงิน',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ภาพสลิปการโอนเงินผ่านธนาคาร'
				},
			}
		},
		customer_orders: {
			title: 'รายการคำสั่งซื้อจากลูกค้า',
			header: 'รายการคำสั่งซื้อจากลูกค้า',
			search: {
				search_placeholder: 'หมายเลขคำสั่งซื้อ, ชื่อลูกค้า',
				status: 'สถานะ',
				all_status: 'ทั้งหมด',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			result_title: 'รายการคำสั่งซื้อทั้งหมด',
			item_col: 'หมายเลขคำสั่งซื้อ',
			item_col_cod: 'COD',
			item_col2: 'ยอดรวมทั้งหมด',
			item_col3: 'สถานะ',
			item_col4: 'วันที่สั่งซื้อ',
			item_col5: 'Action',
			no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อจากลูกค้า',
			save_status_success: 'บันทึกสถานะสั่งซื้อเรียบร้อยแล้ว',
			status: {
				cancelled: 'ยกเลิกคำสั่งซื้อ',
				new: 'ได้รับคำสั่งซื้อ',
				paid: 'จ่ายเงินแล้ว',
				confirm_paid: 'ยืนยันการจ่ายแล้ว',
				ready_to_ship: 'พร้อมจัดส่ง',
				shipped: 'จัดส่งแล้ว',
				delivered: 'ส่งถึงปลายทางแล้ว',
				cancel: 'ยกเลิก',
				return: 'ส่งสินค้าคืน',
			},
			detail: {
				title: 'คำสั่งซื้อจากลูกค้า',
				header: 'จัดการข้อมูลคำสั่งซื้อจากลูกค้า',
				status_action: 'บันทึกสถานะ',
				shipping_action: 'เลือกขนส่งที่จะใช้บริการ',
				form_header: 'หมายเลขคำสั่งซื้อ',
				form_sub_header: 'รายการสินค้าและรายละเอียดที่อยู่จัดส่ง',
				created_date: 'จัดทำวันที่',
				external_remark: 'สิทธิประโยชน์',
				results: {
					item_col0: 'ลำดับ',
					item_col: 'ชื่อสินค้า',
					item_col2: 'ราคาต่อชิ้น',
					item_col3: 'จำนวน',
					item_col4: 'ยอดรวม',
					unit_price: 'ราคา',
					qty: 'จำนวน',
					total: 'ยอดรวม',
					shipping_price: 'ค่าจัดส่ง',
					discount_price: 'ส่วนลด',
					total_price: 'ราคาสินค้า',
					net_price: 'ยอดที่ได้รับชำระ',
				},
				payment: {
					header: 'รายละเอียดการชำระเงิน',
					cod: 'ลูกค้าเลือกชำระปลายทาง (COD)',
					no_data: 'ยังไม่ได้รับแจ้งการจ่ายเงิน',
					action: 'ยืนยันรับชำระเงิน',
					confirm_message: 'ยืนยันการชำระเงินถูกต้องตามคำสั่งซื้อ?',
					save_success: 'ยืนยันการชำระเงินเรียบร้อยแล้ว'
				},
				parcels: {
					header: 'รายการจัดเตรียมพัสดุ',
					sub_header: 'หากมีการปรับเปลี่ยนข้อมูลพัสดุ กรุณาบันทึกข้อมูล เพื่อแสดงรายการขนส่งตามจำนวนพัสดุ',
					cod_hint: '***ค่าเก็บเงินปลายทางอยู่ในช่วง 100 - 50,000 บาท',
					is_parcel_ready: 'เตรียมพัสดุพร้อมแล้วสำหรับการจัดส่ง',
					confirm_ready: 'กรุณายืนยันการเตรียมพัสดุก่อนการส่ง ซึ่งจะทำให้คุณจะไม่สามารถแก้ไขรายการพัสดุและตรวจสอบขนส่งได้',
					no_data: 'กรุณาเพิ่มรายการพัสดุที่บรรจุสินค้า',
					box: 'ขนาดกล่อง / ซอง',
					parcel_weight: 'น้ำหนัก',
					parcel_width: 'กว้าง',
					parcel_length: 'ยาว',
					parcel_height: 'สูง',
					g: 'กรัม',
					cm: 'ซม',
					cod_amount: 'เก็บเงินปลายทาง',
				},
				shipping: {
					header: 'ตรวจสอบขนส่ง',
					courier_id: 'เลือกขนส่ง',
					courier_parcel: 'ชิ้นที่ %{index} - %{remark}',
					courier_parcel_optional: 'สามารถส่งได้',
					courier_parcel_hint: '***ตัวอักษรสีแดงแทนพัสดุที่ขนส่งไม่สามารถส่งได้ กรุณาตรวจสอบพัสดุและเลือกขนส่งใหม่อีกครั้ง',
				},
				booking: {
					header: 'รายการจัดส่งพัสดุ',
					courier: 'ดำเนินการขนส่งโดย : %{courier}',
					date: 'อัพเดตสถานะเมื่อวันที่ : %{time}',
					parcel: 'พัสดุชิ้นที่ #%{index}',
					description: '%{title}, น้ำหนัก %{weight} กรัม',
					cod: 'เก็บเงินปลายทาง',
				},
				remark: {
					header: 'ข้อมูลเข้าระบบ AAP',
					external_member_no: 'รหัสสมาชิก',
					remark: 'ลิงค์ติดตามสถานะสินค้า'
				},
				save_success: 'บันทึกข้อมูลเรียบร้อยแล้ว',
			},
			waiting: {
				title: 'รายการขนส่งที่จัดเตรียมพัสดุ',
				header: 'รายการขนส่งที่จัดเตรียมพัสดุ',
				result_title: 'รายการขนส่งทั้งหมด',
				no_shipping_enabled: 'เปิดการใช้งานเชื่อมต่อบริการระบบขนส่ง เพื่อเข้าถึงการใช้งานที่สะดวกยิ่งขึ้น',
				item_col: 'ชื่อขนส่ง',
				item_col2: 'ช่วงเวลาตัดยอด',
				item_col3: 'การเข้ารับ',
				item_col4: 'จำนวนขั้นต่ำ',
				item_col5: 'จำนวนที่ต้องส่ง',
				item_col6: 'Action',
				action_booking: 'พร้อมส่งพัสดุ',
				status_booked: 'จัดส่งเรียบร้อยแล้ว',
				confirm_message: 'กรุณายืนยันรายละเอียดด้านล่าง ก่อนเริ่มการจัดส่งพัสดุทั้งหมด',
				no_data: 'ไม่มีพัสดุสำหรับการจัดส่งนี้',
			},
			tracking: {
				title: 'รายการจัดส่งสินค้า',
				header: 'รายการจัดส่งสินค้า',
				action: 'เพิ่มรายการ',
				search: {
					search_placeholder: 'ค้นหาจากหมายเลขจัดส่ง',
					status: 'สถานะ',
					all_status: 'ทั้งหมด',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				no_shipping_enabled: 'เปิดการใช้งานเชื่อมต่อบริการระบบขนส่ง เพื่อเข้าถึงการใช้งานที่สะดวกยิ่งขึ้น',
				result_title: 'รายการจัดส่งสินค้าทั้งหมด',
				item_col: 'หมายเลขจัดส่ง (จำนวนพัสดุ)',
				item_col2: 'ชื่อขนส่ง',
				item_col3: 'วันที่จัดส่ง',
				item_col4: ' ',
				no_data: 'ไม่พบข้อมูลรายการจัดส่งสินค้า',
				modal: {
					title: 'หมายเลขจัดส่ง #%{no}',
					subheader: 'หมายเลขคำสั่งซื้อและรายการพัสดุตามรายละเอียดด้านล่างนี้',
					item_no: 'พัสดุ #%{index} - %{no}',
					item_action: 'ดูรายละเอียด',
					printing_action: 'สั่งพิมพ์ใบปะหน้า',
				},
				printing: {
					title: 'รายละเอียดใบปะหน้า',
					header: 'รายละเอียดใบปะหน้า #%{no}',
					action: 'สั่งพิมพ์ใบปะหน้า',
					search: {
						search_placeholder: 'ค้นหาจากหมายเลขจัดส่ง',
						size: {
							default: 'ขนาดใบปะหน้า',
							a4: 'กระดาษ A4',
							a5: 'กระดาษ A5',
							a6: 'กระดาษ A6',
							receipt: 'ใบเสร็จ',
							letter: 'ซองจดหมาย',
							letter4x6: 'ซองจดหมาย 4x6',
							sticker: 'สติกเกอร์ 8x8 เซนติเมตร',
							sticker4x6: 'สติกเกอร์ 4x6 นิ้ว',
							paperang: 'เครื่องปริ๊น Paperang',
						},
						tracking_code: 'หมายเลข Tracking Code',
						all_tracking_code: 'ทั้งหมด'
					},
					result_title: 'รายการจัดส่งสินค้าทั้งหมด',
					item_col: 'หมายเลขจัดส่ง (จำนวนพัสดุ)',
					item_col2: 'ชื่อขนส่ง',
					item_col3: 'วันที่จัดส่ง',
					item_col4: ' ',
					no_data: 'ไม่พบข้อมูลรายการจัดส่งสินค้า',
					modal: {
						title: 'หมายเลขจัดส่ง #%{no}',
						subheader: 'หมายเลขคำสั่งซื้อและรายการพัสดุตามรายละเอียดด้านล่างนี้',
						item_no: 'พัสดุ #%{index} - %{no}',
						item_action: 'ดูรายละเอียด',
						printing_action: 'สั่งพิมพ์ใบปะหน้า',
					}
				},
			},
		}
	},
	redeem: {
		gifts: {
			title: 'รายการของรางวัล',
			header: 'รายการของรางวัล',
			search_placeholder: 'ชื่อรางวัล',
			result_title: 'รายการรางวัลทั้งหมด',
			point_prefix: 'ปัจจุบันคุณมี',
			point_suffix: 'คะแนน',
			item_col: 'ชื่อรางวัล',
			item_col2: 'ใช้คะแนน',
			item_col3: 'ช่วงเวลาแลก',
			item_col4: ' ',
			item_col4_action: 'แลกรางวัล',
			no_data: 'ไม่พบข้อมูลรายการของรางวัล',
			create_success: 'บันทึกการแลกรางวัลเรียบร้อยแล้ว'
		},
		histories: {
			title: 'ประวัติแลกรางวัล',
			header: 'ประวัติแลกรางวัล',
			search_placeholder: 'ชื่อรางวัล',
			result_title: 'รายการประวัติแลกรางวัลทั้งหมด',
			item_col: 'ชื่อรางวัล',
			item_col2: 'ใช้คะแนน',
			item_col3: 'วันที่แลก',
			item_col4: 'สถานะ',
			no_data: 'ไม่พบข้อมูลประวัติแลกรางวัล',
			status: {
				waiting: 'รอการตรวจสอบ',
				approved: 'อนุมัติแล้ว',
				rejected: 'ไม่ผ่านการอนุมัติ'
			}
		},
	},
	member: {
		guests: {
			title: 'รายการผู้มุ่งหวัง',
			header: 'รายการผู้มุ่งหวัง',
			search: {
				search_placeholder: 'ค้นหาชื่อ, เบอร์โทรศัพท์',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			result_title: 'รายการผู้มุ่งหวังทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'เบอร์โทรศัพท์',
			item_col3: 'อนุมัติคูปอง',
			item_col4: 'จำนวนดูคลิป (ครั้ง)',
			item_col5: 'เข้าใช้งานล่าสุด',
			item_col6: 'วันที่สมัคร',
			item_col7: 'Actions',
			item_col7_no_upgrade: 'ยังไม่อัพเกรด',
			item_col7_btn_convert: 'อนุมัติอัพเกรด',
			item_col7_btn_converted: 'อนุมัติแล้ว',
			approve_fail: 'ไม่สามารถอนุมัติได้ กรุณาติดต่อผู้ดูแลอีกครั้ง',
			no_data: 'ไม่พบข้อมูลรายการผู้มุ่งหวัง',
			download_excel: 'ดาวน์โหลด Excel'
		},
		members: {
			title: 'รายการสมาชิกในทีม',
			header: 'รายการสมาชิกในทีม',
			change_view_network: 'ดูมุมมองเครือข่าย',
			change_view_table: 'ดูมุมมองตาราง',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'Social Network',
			item_col4: 'เข้าใช้งานล่าสุด',
			item_col5: 'วันที่สมัคร',
			item_col6: 'Actions',
			network_title: 'แสดงสมาชิกตามลำดับขั้น',
			no_data: 'ไม่พบข้อมูลรายการสมาชิกในทีม',
			filter: {
				section: 'รายละเอียดการค้นหา',
				group_id: 'ระดับสมาชิก',
				province_code: 'จังหวัด',
				province_code_all: 'ทุกจังหวัด',
			},
			activities: {
				title: 'รายการประวัติกิจกรรมของสมาชิก',
				header: 'รายการประวัติกิจกรรมของสมาชิก',
				tab: {
					title: 'การดูสื่อความรู้',
					search_placeholder: 'ชื่อบทความหรือวิดีโอ',
					result_title: 'รายการสื่อความรู้ทั้งหมด',
					item_col: 'ชื่อสื่อความรู้',
					item_col2: 'หมวดหมู่',
					item_col3: 'ชั่วโมงการเรียนรู้',
					item_col3_format: '%{hour} ชม. %{min} นาที',
					no_data: 'ไม่พบข้อมูลรายการประวัติการดูสื่อความรู้',
					filter: {
						section: 'รายละเอียดการค้นหา',
						month: 'เดือนที่',
						month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
						year: 'ปีที่',
						year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					},
				},
				tab2: {
					title: 'พาสปอร์ต',
					result_title: 'รายการสรุปคะแนนรายวันทั้งหมดในเดือนนี้',
					search: {
						start_date: 'วันเริ่มต้น',
						end_date: 'ถึง'
					},
					no_data: {
						title: 'ไม่พบข้อมูลประวัติพาสปอร์ต',
						desc: 'เนื่องด้วยไม่มีรายการเป้าหมายที่ดำเนินการในช่วงเวลานี้',
						desc_2: 'คุณสามารถแจ้งสมาชิกได้อีกครั้ง เพื่อเริ่มบันทึกเป้าหมายใหม่ได้ทันที',
					},
					item_col: 'วันที่',
					item_col2: 'เปอร์เซ็นต์',
				},
			}
		},
	},
	kpi: {
		achievements: {
			title: 'รายการเป้าหมาย',
			header: 'รายการเป้าหมาย',
			no_data: 'แล้วพบกับ KPI รูปแบบใหม่ เร็วๆ นี้',
			tab: {
				title: 'พาสปอร์ต',
				result_title: 'รายการเป้าหมายทั้งหมด',
				section: '%{name} %{point} คะแนน',
				no_data: {
					title: 'ไม่พบข้อมูลรายการเป้าหมาย',
					desc: 'เนื่องด้วยไม่มีรายการเป้าหมายถูกกำหนดในช่วงเวลานี้',
					desc_2: 'กรุณาติดต่อผู้ดูแลระบบ เพื่อดำเนินการสร้างรายการเป้าหมายทันที',
				},
				qualified: {
					title: 'พิชิตเป้าหมายแล้ว',
					unit: 'คะแนนของคุณ'
				},
				target: {
					title: 'สำเร็จไปแล้ว',
					unit: 'จากเป้าหมายทั้งหมด'
				},
				save_success: 'บันทึกเป้าหมายใหม่เรียบร้อยแล้ว',
			},
			tab2: {
				title: 'ประวัติรายวัน',
				result_title: 'รายการสรุปคะแนนรายวันทั้งหมดในเดือนนี้',
				search: {
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				no_data: {
					title: 'ไม่พบข้อมูลประวัติพาสปอร์ต',
					desc: 'เนื่องด้วยไม่มีรายการเป้าหมายที่ดำเนินการในช่วงเวลานี้',
					desc_2: 'คุณสามารถกลับไปที่ "พาสปอร์ต" เพื่อเริ่มบันทึกเป้าหมายใหม่ได้ทันที',
				},
				item_col: 'วันที่',
				item_col2: 'เปอร์เซ็นต์',
			}
		},
		leaders: {
			title: 'รายการผู้พิชิตเป้าหมาย',
			header: 'รายการผู้พิชิตเป้าหมาย',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'คะแนน',
			no_data: 'ไม่พบข้อมูลรายการผู้พิชิตเป้าหมาย',
		},
	},
	star: {
		learners: {
			title: 'รายการนักเรียนรู้',
			header: 'รายการนักเรียนรู้',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'ชั่วโมงการเรียนรู้',
			item_col3_format: '%{hour} ชม. %{min} นาที',
			no_data: 'ไม่พบรายการนักเรียนรู้',
			filter: {
				section: 'รายละเอียดการค้นหา',
				month: 'เดือนที่',
				month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				year: 'ปีที่',
				year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
			},
		},
		promoters: {
			title: 'รายการนักโปรโมท',
			header: 'รายการนักโปรโมท',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'จำนวนแชร์เซลเพจ',
			item_col4: 'จำนวนแชร์ Line Content',
			item_col5: 'รวมทั้งหมด',
			no_data: 'ไม่พบข้อมูลรายการนักโปรโมท',
			filter: {
				section: 'รายละเอียดการค้นหา',
				month: 'เดือนที่',
				month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				year: 'ปีที่',
				year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
			},
		},
		qualified: {
			title: 'รายการผู้ประสบความสำเร็จ',
			header: 'รายการผู้ประสบความสำเร็จ',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'วันที่เข้าร่วม',
			},
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'ข้อความ',
			no_data: 'ไม่พบข้อมูลรายการผู้ประสบความสำเร็จ',
			filter: {
				section: 'Info',
				month: 'เดือนที่',
				month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				year: 'ปีที่',
				year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
			},
			card: {
				joined_date: 'เข้าร่วมวันที่',
				quote: 'Quote:'
			}
		},
	},
	content: {
		article_categories: {
			title: 'รายการหมวดหมู่บทความ',
			header: 'รายการหมวดหมู่บทความ',
			search_placeholder: 'ชื่อหมวดหมู่',
			card: {
				count: 'จำนวน %{count} บทความ',
			},
			no_data: 'ไม่พบข้อมูลรายการหมวดหมู่บทความ',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
		},
		articles: {
			title: 'รายการบทความ',
			header: 'รายการบทความ',
			search_placeholder: 'ชื่อบทความ',
			ep: 'บทที่ %{ep}',
			no_data: 'ไม่พบข้อมูลรายการบทความ',
			filter: {
				section: 'รายละเอียดการค้นหา',
				tags: 'แท็ก'
			},
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
				item3: 'ตอนที่ 0-9'
			},
			detail: {
				no_access: {
					title: 'ไม่สามารถดูบทความได้',
					desc: 'เนื่องจากคุณไม่ได้ถูกกำหนดสิทธิ์ให้สามารถ',
					desc_2: 'ดูบทความนี้ กรุณาติดต่อผู้ดูแลระบบเพื่อสอบถามสิทธิ์ในการเข้าถึง',
				},
				date: 'จัดทำเมื่อ',
				next_ep: 'รายการถัดไป',
				related_list: 'รายการที่เกี่ยวข้อง'
			}
		},
		video_categories: {
			title: 'รายการหมวดหมู่วิดีโอ',
			header: 'รายการหมวดหมู่วิดีโอ',
			search_placeholder: 'ชื่อหมวดหมู่',
			card: {
				count: 'จำนวน %{count} วิดีโอ',
			},
			no_data: 'ไม่พบข้อมูลรายการหมวดหมู่วิดีโอ',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
		},
		videos: {
			title: 'รายการวิดีโอ',
			header: 'รายการวิดีโอ',
			search_placeholder: 'ชื่อวิดีโอ',
			no_data: 'ไม่พบข้อมูลรายการวิดีโอ',
			ep: 'ตอนที่ %{ep}',
			filter: {
				section: 'รายละเอียดการค้นหา',
				tags: 'แท็ก'
			},
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
				item3: 'ตอนที่ 0-9'
			},
			detail: {
				no_access: {
					title: 'ไม่สามารถดูวิดีโอได้',
					desc: 'เนื่องจากคุณไม่ได้ถูกกำหนดสิทธิ์ให้สามารถ',
					desc_2: 'ดูวิดีโอนี้ กรุณาติดต่อผู้ดูแลระบบเพื่อสอบถามสิทธิ์ในการเข้าถึง',
				},
				date: 'จัดทำเมื่อ',
				next_ep: 'รายการถัดไป',
				related_list: 'รายการที่เกี่ยวข้อง'
			}
		},
		specials: {
			title: 'รายการวิดีโอ',
			header: 'รายการวิดีโอ',
			search_placeholder: 'ชื่อวิดีโอ',
			no_data: 'ไม่พบข้อมูลรายการวิดีโอ',
			ep: 'ตอนที่ %{ep}',
			filter: {
				section: 'รายละเอียดการค้นหา',
				tags: 'แท็ก'
			},
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
				item3: 'ตอนที่ 0-9'
			},
			detail: {
				no_access: {
					title: 'ไม่สามารถดูวิดีโอได้',
					desc: 'เนื่องจากคุณไม่ได้ถูกกำหนดสิทธิ์ให้สามารถ',
					desc_2: 'ดูวิดีโอนี้ กรุณาติดต่อผู้ดูแลระบบเพื่อสอบถามสิทธิ์ในการเข้าถึง',
				},
				date: 'จัดทำเมื่อ',
				next_ep: 'รายการถัดไป',
				related_list: 'รายการที่เกี่ยวข้อง'
			}
		},
	},
	apps: {
		shipping: {
			title: 'ตั้งค่าเชื่อมต่อระบบขนส่ง',
			header: 'ตั้งค่าเชื่อมต่อระบบขนส่ง',
			tab: {
				title: 'ตั้งค่าทั่วไป',
				general: {
					header: 'ข้อมูลการตั้งค่าทั่วไป',
					is_cod_enabled: 'เปิดการใช้งานตัวเลือกชำระเงิน Cash on Delivery (COD)',
					is_cod_enabled_hint: 'เปิดเพื่อให้ลูกค้าสามารถเลือกรูปแบบการชำระเงินกับระบบขนส่งได้',
					is_enabled: 'เปิดการใช้งานเชื่อมต่อการส่งสินค้าผ่าน Shippop',
					is_enabled_hint: 'เชื่อมต่อกับ Shippop หากคุณยังไม่ได้สมัครเพื่อเปิดการใช้งาน, กรุณากรอกแบบฟอร์มด้านล่างเพื่อรอเจ้าหน้าที่ติดต่อกลับ, หรือปิดตัวเลือกนี้หากต้องการจัดการส่งสินค้าดัวตัวเอง',
					api_key: 'API Key',
					api_key_placeholder: 'เช่น dv2e3e186c8a17f392451502354b134ae...',
					api_key_hint: 'ข้อมูลนี้จะได้รับหลังจากสมัครเริ่มใช้งานกับ Shippop แล้ว',
				},
				embeded: {
					header: 'สมัครเพื่อเปิดใช้งานขนส่งกับ Shippop',
				},
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			},
			tab2: {
				title: 'รายการขนส่ง',
				header: 'รายการขนส่งทั้งหมด',
				subheader: 'เปิด-ปิด เพื่อเลือกรายการขนส่งที่คุณต้องการเปิดให้ลูกค้าเลือกใช้บริการ',
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			}
		},
		payment: {
			title: 'ตั้งค่าเชื่อมต่อระบบจ่ายเงิน',
			header: 'ตั้งค่าเชื่อมต่อระบบจ่ายเงิน',
			tab: {
				title: 'ตั้งค่าทั่วไป',
				general: {
					header: 'ข้อมูลการตั้งค่าทั่วไป',
					bank_code: 'ชื่อธนาคาร',
					bank_account_no: 'หมายเลขบัญชีธนาคาร',
					bank_account_no_placeholder: 'XXX-X-XXXXX-X',
					bank_account_name: 'ชื่อบัญชีธนาคาร',
					bank_account_name_placeholder: 'ชื่อเจ้าของบัญชี',
					is_enabled: 'เปิดการใช้งานเชื่อมต่อการจ่ายเงินผ่าน GB Prime Pay',
					is_enabled_hint: 'เชื่อมต่อกับ GB Prime Pay หากคุณยังไม่ได้สมัครเพื่อเปิดการใช้งาน, กรุณาจัดเตรียมเอกสารตามแบบฟอร์มด้านล่าง, หรือปิดตัวเลือกนี้หากต้องการใช้เฉพาะวิธีการจ่ายแบบโอนผ่านบัญชีธนาคาร',
					token_key: 'Token Key',
					token_key_placeholder: 'เช่น XKfU1U4NMU20umP7DsDuYgf5iQMcweOiF...',
					public_key: 'Public Key',
					public_key_placeholder: 'เช่น giYI8590ihq14zdTOEDeVK6JhG6qd1Nq',
					secret_key: 'Secret Key',
					secret_key_placeholder: 'เช่น q3YzkesZgHOe4bTrtdfVE15uHl1hJffo',
					secret_key_hint: 'ข้อมูล 3 ชุดนี้จะได้รับหลังจากสมัครเริ่มใช้งานกับ GB Prime Pay แล้ว',
					validator: {
						bank_code: '^กรุณาเลือกธนาคาร',
						bank_account_no: '^กรุณากรอกหมายเลขบัญชีธนาคาร',
						bank_account_name: '^กรุณากรอกชื่อบัญชีธนาคาร',
					}
				},
				embeded: {
					header: 'สมัครเพื่อเปิดใช้งานการจ่ายเงินกับ GB Prime Pay',
				},
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			},
			tab2: {
				title: 'วิธีการจ่ายเงิน',
				header: 'รายการวิธีจ่ายเงินทั้งหมด',
				subheader: 'เปิด-ปิด เพื่อเลือกรายการวิธีจ่ายเงินที่คุณต้องการเปิดให้ลูกค้าเลือกใช้บริการ',
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			}
		},
		liff: {
			title: 'รายการของ Line Content',
			header: 'รายการ Line Content',
			search_placeholder: 'ชื่อ Content',
			filter: {
				section: 'รายละเอียดการค้นหา',
				category_id: 'หมวดหมู่',
				category_id_all: 'ทุกหมวดหมู่',
			},
			result_title: 'รายการ Content ทั้งหมด',
			item_col: 'ชื่อ Content',
			item_col2: ' ',
			item_col2_action: 'แชร์เลย',
			no_data: 'ไม่พบข้อมูลรายการของ Line Content',
			card: {
				time: 'จำนวนแชร์',
				unit: 'ครั้ง',
				btn_example: 'ดูตัวอย่างแชร์',
				btn_share_now: 'แชร์เลย'
			},
			example: {
				header: 'ตัวอย่างการแสดงผล Content',
				showContact: 'แนบการติดต่อกลับ',
				dialog: {
					agreement: 'เนื่องจากข้อมูลบัตรประชาชนของคุณยังไม่ครบถ้วน ต้องการตั้งค่าทันทีหรือไม่?',
				}
			},
			send_success: 'ส่ง Line Content เรียบร้อยแล้ว'
		},
		page: {
			title: 'เซลเพจ',
			header: 'รายการเซลเพจ',
			import_action: 'ดาวน์โหลดเซลเพจตั้งต้น',
			limit: 'จำนวนเซลเพจที่สร้างแล้ว %{total}/%{limit} หน้า',
			no_data: 'ไม่พบข้อมูลรายการของเซลเพจ',
			card: {
				default: 'หน้าหลัก',
				btn_example: 'ดูตัวอย่างแชร์',
				btn_share_now: 'แชร์ผ่านไลน์',
				copy_result: 'คัดลอกข้อความเรียบร้อยแล้ว'
			},
			example: {
				header: 'ตัวอย่างการแสดงผล Content',
			},
			send_success: 'แชร์เซลเพจผ่าน Line เรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
				item3: 'เรตติ้ง',
			},
		},
		facebook_group: {
			title: 'รายการกลุ่มเฟซบุ๊ก',
			header: 'รายการกลุ่มเฟซบุ๊ก',
			result_title: 'รายการกลุ่มเฟซบุ๊กทั้งหมด',
			item_col: 'ลิงค์กลุ่ม',
			item_col2: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการกลุ่มเฟซบุ๊ก',
		},
	},
	upgrade: {
		title: 'อัพเกรดสถานะ',
		header: 'ลงทะเบียนตามรายละเอียดผู้สมัคร ดังนี้',
		subheader: 'กรุณาตรวจสอบความถูกต้องก่อนการดำเนินการ',
		form: {
			name: 'ชื่อ-นามสกุล',
			email: 'อีเมล',
			phone: 'เบอร์โทรศัพท์',
		},
		stepper: {
			step1: {
				default: 'ขั้นตอนสมัครสมาชิก',
				header: 'รูปแบบการชำระเงิน "โอนเงิน"',
				sub_header: 'อัพเกรดบัญชีผู้ใช้งานตาม Package ที่คุณเลือก โดยคุณจะได้รับสิทธิประโยชน์มากมายกับ Platform มากขึ้น ตาม Package ที่เลือก',
				btn_copy: 'คัดลอกบัญชี',
				btn_copied: 'คัดลอกแล้ว',
				attach: 'แนบสลิปการโอนเงิน',
				attach_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				attach_info: 'ภาพแสดงรายละเอียดการโอนเงินชัดเจน',
				btn_action: 'แจ้งรายละเอียดการชำระเงิน',
			},
			step2: {
				default: 'สถานะการชำระเงิน',
				header: 'ส่งข้อมูลการชำระเงินแล้ว',
				// sub_header: 'หลังจากส่งเอกสารการโอนเงินแล้ว ทางผู้ดูแลระบบจะตรวจสอบและแจ้งผลทางอีเมล์อีกครั้ง',
				sub_header: 'หลังจากส่งเอกสารการโอนเงินแล้ว หากมีข้อสงสัยสามารถติดต่อได้ที่ LINE',
				btn_action: 'กดเพิ่มเพื่อนผ่าน Line',
			}
		},
		all_done: 'แจ้งชำระเงินเรียบร้อย รอผู้ดูแลระบบตรวจสอบข้อมูล...'
	},
	account: {
		accounts: {
			title: 'รายการลูกค้า',
			header: 'รายการลูกค้า',
			search_placeholder: 'ชื่อลูกค้า, เบอร์โทรศัพท์, อีเมล',
			result_title: 'รายการลูกค้าทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'เบอร์โทรศัพท์',
			item_col3: 'จังหวัด',
			item_col4: 'วันที่ซื้อสินค้าครั้งแรก',
			item_col5: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการลูกค้า',
			filter: {
				section: 'รายละเอียดการค้นหา',
				province_code: 'จังหวัด',
				province_code_all: 'ทุกจังหวัด',
			},
		},
		details: {
			title: 'รายละเอียดลูกค้า',
			header: 'รายละเอียดลูกค้า',
			tab: {
				default: 'ข้อมูลทั่วไป',
				converted_date: 'วันที่กลายเป็นลูกค้า',
				profile: {
					header: 'ข้อมูลส่วนตัว',
					firstname: 'ชื่อจริง',
					lastname: 'นามสกุล',
					email: 'อีเมล',
					phone: 'เบอร์โทรศัพท์',
					phone_placeholder: 'เช่น 081 234 5678',
					address: 'ที่อยู่',
					address_placeholder: 'เช่น บ้านเลขที่ 1/127 ถนนเพชรบุรี',
					postal_code: 'รหัสไปรษณีย์',
					province_name: 'จังหวัด',
					city_name: 'อำเภอ/เขต',
					district_name: 'ตำบล/แขวง',
					validator: {
						email: '^กรุณากรอกอีเมล์',
						email_invalid: '^อีเมล์ไม่ถูกต้อง',
						firstname: '^กรุณากรอกชื่อจริง',
						lastname: '^กรุณากรอกนามสกุล',
						phone: '^กรุณากรอกเบอร์โทรศัพท์',
						address: '^กรุณากรอกที่อยู่',
						postal_code: '^กรุณากรอกรหัสไปรษณีย์',
						sub_domain: '^กรุณากรอกชื่อเว็บไซต์ส่วนตัว',
						sub_domain_length: '^ความยาวชื่อเว็บไซต์ส่วนตัวอย่างน้อย 6 ตัวอักษร',
						sub_domain_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
					},
				}
			},
			tab2: {
				default: 'ประวัติสั่งซื้อ',
				search: {
					search_placeholder: 'ค้นหาหมายเลขคำสั่งซื้อ',
					status: 'สถานะ',
					all_status: 'ทั้งหมด',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				result_title: 'รายการคำสั่งซื้อทั้งหมด',
				item_col: 'หมายเลขคำสั่งซื้อ',
				item_col_cod: 'COD',
				item_col2: 'ยอดรวมทั้งหมด',
				item_col3: 'สถานะ',
				item_col4: 'วันที่สั่งซื้อ',
				item_col5: 'Action',
				no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อจากลูกค้า',
			},
		}
	},
	leads: {
		title: 'Lead',
		header: 'รายการโทรนัด',
		action: 'เพิ่มรายการ',
		result_title: 'รายการโทรนัดทั้งหมด',
		item_col: 'ชื่อ-เบอร์โทรศัพท์',
		item_col2: 'วันที่',
		item_col3: 'ระยะเวลา',
		item_col3_format: '%{min} นาที %{sec} วินาที',
		no_data: 'ไม่พบข้อมูลรายการโทรนัด',
		no_data: {
			title: 'ไม่พบข้อมูลรายการโทรนัด',
			desc: 'คุณสร้างสามารถสร้างการโทรนัดของคุณเองได้',
			desc_2: 'ผ่านตัวช่วยสร้างการโทรนัดได้อย่างมีประสิทธิภาพ, เพียงคลิก',
			btn_add: 'เพิ่มการโทรใหม่ทันที',
			remark: 'เพื่อดำเนินการต่อ',
		},
		sorting: {
			item: 'ชื่อ ก-ฮ',
			item2: 'ล่าสุด',
		},
		create: {
			header: 'สร้างการโทรนัด',
			save_fail: 'ไม่สามารถบันทึกข้อมูลโทรนัดได้',
		},
		detail: {
			name: 'ชื่อ-สกุล',
			phone: 'เบอร์โทรศัพท์',
			duration: 'ระยะเวลาในการโทร',
			status: 'สถานะ',
			all_status: 'เลือกสถานะ',
			status_received: 'รับสาย',
			status_missed: 'ไม่รับสาย',
			validator: {
				name: '^กรุณากรอกชื่อ-สกุล',
				phone: '^กรุณากรอกเบอร์โทรศัพท์'
			},
		}
	},
};
