import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';

import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4, 1),
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 'unset',
			width: '100%',
			height: '100%',
			background: 'url("/images/illustrations/undraw_target_kriv.svg")',
			transform: 'unset',
			opacity: 0.6
		},
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(9)
	},
	actionButton: {
		animation: '$blinkingBackground 1s infinite'
	},
	'@keyframes blinkingBackground': {
		'0%': {
			backgroundColor: theme.palette.secondary.main
		},
		'50%': {
			backgroundColor: theme.palette.secondary.dark
		},
		'100%': {
			backgroundColor: theme.palette.secondary.light
		},
	},
}));

function Signup({ className, ...rest }) {
	const classes = useStyles();

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<div>
					<Typography
						variant="h2"
					>
						<strong>{locale.t('overview.signup.title')}</strong>
					</Typography>
					<div className={classes.details}>
						<Typography
							variant="body1"
						>
							{/* {locale.t('overview.signup.caption')} */}
							เพียงแค่คุณเป็นสมากชิกครั้งเดียวก็ได้รับสิทธิ์ เข้าโปรแกรม AAP [Apco Affiliate Program] ตลอด 100 ปี สอบถามคลิกที่ปุ่มด้านล่าง
						</Typography>
					</div>
					<Button
						className={classes.actionButton}
						// color="secondary"
						component={RouterLink}
						to="/upgrade"
						size="large"
						endIcon={<ArrowForwardRoundedIcon />}
						variant="contained"
					>
						{locale.t('overview.signup.btn_action')}
					</Button>
				</div>
			</CardContent>
		</Card>
	);
}

Signup.propTypes = {
	className: PropTypes.string,
};

export default Signup;
